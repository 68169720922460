<template>
  <div class="address">
    <div
      v-if="$slots.icon"
      class="address__icon"
    >
      <slot name="icon" />
    </div>
    <address class="address__info">
      <slot v-bind="{ company_name, address_1, house_number, postal_code, city, country_name, name, address_2 }">
        <div v-if="hasName">
          {{ name }}
        </div>
        <div
          v-if="company_name"
          class="address__name"
          data-test="company-name"
        >
          {{ company_name }}
        </div>
        <div>{{ address_1 }} {{ house_number }}</div>
        <div v-if="hasAddress2">
          {{ address_2 }}
        </div>
        <div>{{ postal_code }}, {{ city }}</div>
        <div v-if="hasCountryName">
          {{ country_name }}
        </div>
      </slot>
    </address>
  </div>
</template>

<script>
export default {
  name: 'AddressFormatted',
  props: {
    company_name: { type: String, required: false, default: undefined },
    address_1: { type: String, required: true },
    house_number: { type: String, required: false, default: undefined },
    postal_code: { type: String, required: true },
    city: { type: String, required: true },
    country_name: { type: String, required: false, default: undefined },
    name: { type: String, required: false, default: undefined },
    address_2: { type: String, required: false, default: '' },
  },
  computed: {
    hasAddress2() {
      return this.address_2 !== ''
    },
    hasName() {
      return this.name !== undefined
    },
    hasCountryName() {
      return this.country_name !== undefined
    },
  },
}
</script>

<style lang="scss" scoped>
.address {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.address__icon {
  min-width: 16px;
  margin-right: 0.625rem;
}

.address__info {
  flex-grow: 1;
  font-style: normal;
  width: 100%;
  word-break: break-all;
}

.address__name {
  font-weight: 800;
}
</style>
