<template>
  <TheWrapper class="summary">
    <h2 class="step__main-title">
      {{ $t('Summary') }}
    </h2>
    <form
      class="summary__form"
      data-test="summary-form"
      @submit.prevent="confirm"
    >
      <div
        class="summary__contact-details"
        data-test="contact-details"
        :class="{ 'summary__contact-details--error': v$.$anyError }"
      >
        <div class="summary__entry">
          <label>
            <span
              class="summary__label"
              for="email"
            >{{ $t('Your email') }}</span>
            <input
              :value="email"
              :class="{ 'summary__input--error': v$.email.$error }"
              class="summary__input"
              type="text"
              name="email"
              data-test="email"
              required
              @change="changeField('email', $event.target.value)"
            >
          </label>
          <p
            v-if="v$.email.$error"
            class="field-error field-error--mobile"
          >
            {{ $t('A valid e-mail address is required') }}
          </p>
        </div>

        <div class="summary__entry">
          <label>
            <span
              class="summary__label"
              for="phone"
            >{{ $t('Your phone number') }}</span>
            <input
              :value="telephone"
              :class="{ 'summary__input--error': v$.telephone.$error || isTelephoneNumberInvalid }"
              class="summary__input"
              type="text"
              name="phone"
              data-test="phone"
              required
              @change="changeField('telephone', $event.target.value)"
            >
          </label>
          <p
            v-if="v$.telephone.$error"
            class="field-error field-error--mobile"
          >
            {{ $t('Your phone number is required') }}
          </p>
          <p
            v-if="isTelephoneNumberInvalid"
            class="field-error field-error--mobile"
          >
            {{ $t('Please enter a valid phone number') }}
          </p>
        </div>

        <div class="contact-details__errors">
          <p
            v-if="v$.email.$error"
            class="field-error"
          >
            {{ $t('A valid e-mail address is required') }}
          </p>
          <p
            v-if="v$.telephone.$error"
            class="field-error"
          >
            {{ $t('Your phone number is required') }}
          </p>
          <p
            v-if="isTelephoneNumberInvalid"
            class="field-error"
          >
            {{ $t('Please enter a valid phone number') }}
          </p>
        </div>
      </div>
      <div class="card">
        <p class="card__title">
          {{ $t('Your address') }}
        </p>
        <AddressFormatted
          v-if="parcel"
          v-bind="normalizedParcelAddress"
          class="card__address-wrap"
        >
          <template #icon>
            <FontAwesomeIcon :icon="$options.faMapMarkerAlt" />
          </template>
        </AddressFormatted>
        <UiButton
          mode="secondary"
          class="card--align"
        >
          <router-link
            :to="{ name: 'edit-address' }"
            data-test="edit-address-button"
            class="small-button__btn"
          >
            <FontAwesomeIcon
              :icon="$options.faCaretRight"
              class="card__button-icon"
            />
            <span>{{ $t('Edit address') }}</span>
          </router-link>
        </UiButton>
      </div>
      <div
        v-if="returnedItems.length"
        class="card"
      >
        <div class="card__group">
          <p class="card__title">
            {{ $t('Items to return') }}
          </p>
        </div>
        <div
          v-for="item in returnedItems"
          :key="item.id"
          class="card__group card--align"
          data-test="items"
        >
          <span>{{ item.quantity }} x {{ item.name }}</span>
        </div>
        <UiButton
          mode="secondary"
          class="card--align card__button"
        >
          <router-link
            :to="{ name: 'items-selection' }"
            data-test="edit-items-button"
            class="small-button__btn"
          >
            <FontAwesomeIcon
              :icon="$options.faCaretRight"
              class="card__button-icon"
            />
            <span>{{ $t('Edit items') }}</span>
          </router-link>
        </UiButton>
      </div>
      <div
        v-if="refundSelection.code"
        class="card"
      >
        <p class="card__title">
          {{ $t('Refund method') }}
        </p>
        <div class="card__group">
          <FontAwesomeIcon
            :icon="$options.faCoins"
            class="card__icon"
          />
          <span>{{ refundSelection.label }}</span>
        </div>
        <UiButton
          mode="secondary"
          class="card--align card__button"
        >
          <router-link
            :to="{ name: 'refund-options' }"
            data-test="refund-button"
            class="small-button__btn"
          >
            <FontAwesomeIcon
              :icon="$options.faCaretRight"
              class="card__button-icon"
            />
            <span>{{ $t('Change refund method') }}</span>
          </router-link>
        </UiButton>
      </div>
      <div
        class="card"
        data-test="return-methods"
      >
        <p class="card__title">
          {{ $t('Return method') }}
        </p>
        <div class="card__group">
          <FontAwesomeIcon
            :icon="$options.faUndo"
            class="card__icon"
          />
          <div v-if="storeLocation !== undefined && returnLocationType === 'store'">
            <div class="card__sub-title">
              {{ $t('Return in store') }}
            </div>
            <AddressFormatted v-bind="storeLocation" />
          </div>
          <div v-else-if="returnLocationType === 'pickup'">
            <div class="card__sub-title">
              {{ $t('Arrange a pick-up') }}
            </div>
            <span>
              {{
                $t('Next business day, {date} between {startTime} and {endTime}', {
                  date: pickupDate.date,
                  startTime: pickupDate.startTime,
                  endTime: pickupDate.endTime,
                })
              }}
            </span>
          </div>
          <div v-else-if="dropOffPointAddress && returnLocationType === 'dropoff' && !selectedCarrier">
            <div class="card__sub-title">
              {{ $t('At a drop-off point') }}
            </div>
            <AddressFormatted v-bind="dropOffPointAddress" />
          </div>
          <div
            v-else-if="selectedCarrier"
            data-test="shipping-method-description"
          >
            <span class="card__sub-title">{{ selectedCarrier.name }} - {{ $t('At a drop-off point') }}</span>
            <span v-if="isSelectedCarrierErs">{{
              ' ' + $t('of {nationalCarrierName}', { nationalCarrierName: nationalCarrier.name })
            }}</span>
          </div>
          <div v-else-if="labellessDropOffPointAddress && returnLocationType === 'drop_off_labelless'">
            <div class="card__sub-title">
              {{ $t('At a drop-off point - No printer needed') }}
            </div>
            <AddressFormatted v-bind="labellessDropOffPointAddress" />
          </div>
          <div v-else-if="returnLocationType === undefined">
            <div>{{ $t('Please select a return method') }}</div>
          </div>
          <div v-else>
            <div>{{ $t('At postal office') }}</div>
          </div>
        </div>

        <UiButton
          mode="secondary"
          class="card--align card__button"
        >
          <router-link
            :to="{ name: 'return-methods' }"
            data-test="return-button"
            class="small-button__btn"
          >
            <FontAwesomeIcon
              :icon="$options.faCaretRight"
              class="card__button-icon"
            />
            <span>{{ $t('Change return method') }}</span>
          </router-link>
        </UiButton>
      </div>
      <UiButton
        mode="primary"
        class="main-button"
      >
        <button
          class="main-button__btn"
          data-test="main-button"
          :style="brandStyle"
          :disabled="disableNext"
        >
          <span>{{ $t('Confirm') }}</span>
          <FontAwesomeIcon :icon="$options.faChevronRight" />
        </button>
      </UiButton>
    </form>
  </TheWrapper>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faMapMarkerAlt,
  faCaretRight,
  faCoins,
  faUndo,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { mapState, mapGetters } from 'vuex'
import { UiButton } from '@sendcloud/ui-library'
import { email, required } from '@vuelidate/validators'

import { ErrorTypes } from '@/constants.js'
import { APP_CREATE_LABEL } from '@/store/action.types.js'
import { APP_SET_PARCEL } from '@/store/mutation.types'
import { brandColourMixin } from '@/components/mixins.js'
import { handleRouteRejection } from '@/utils'
import TheWrapper from '@/components/layout/TheWrapper.vue'
import AddressFormatted from '@/components/common/AddressFormatted.vue'

export default {
  name: 'ReturnsSummary',
  faMapMarkerAlt,
  faCaretRight,
  faCoins,
  faUndo,
  faChevronRight,
  components: {
    FontAwesomeIcon,
    AddressFormatted,
    TheWrapper,
    UiButton,
  },
  mixins: [brandColourMixin],
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      creatingLabel: false,
      backendErrors: {},
    }
  },
  validations: {
    email: {
      required,
      email,
    },
    telephone: {
      required,
    },
  },
  computed: {
    ...mapGetters(['dropOffPointAddress', 'labellessDropOffPointAddress', 'returnSuccess', 'isInternationalReturn']),
    ...mapState({
      allowedCarriers: (state) => state.allowedCarriers, // TODO: remove when shipping products are implemented
      selectedCarrierCode: (state) => state.selectedCarrierCode, // TODO: remove when shipping products are implemented
      shippingProducts: (state) => state.shippingProducts,
      parcel: (state) => state.parcel,
      storeLocation: (state) => {
        return state.storeAddresses.find((address) => address.id === state.selectedStore)
      },
      refundSelection: (state) => {
        if (state.selectedRefund) return state.selectedRefund
        return { code: undefined, message: '', requireMessage: false }
      },
      returnedItems: (state) => state.returnedItems,
      returnLocationType: (state) => state.returnLocationType,
      nationalCarrier: (state) => state.nationalCarrier,
      pickupDate: (state) => state.pickupDate,
    }),
    telephone() {
      if (!this.parcel) return ''
      return this.parcel.telephone
    },
    email() {
      if (!this.parcel) return ''
      return this.parcel.email
    },
    disableNext() {
      return this.v$.$invalid || this.creatingLabel || !this.email || !this.telephone || !this.returnLocationType
    },
    isTelephoneNumberInvalid() {
      return this.backendErrors.code === 'invalid_telephone_number'
    },
    normalizedParcelAddress() {
      return {
        address_1: this.parcel.address_divided?.street,
        house_number: this.parcel.address_divided?.house_number,
        postal_code: this.parcel.postal_code,
        city: this.parcel.city,
        country_name: this.parcel.country.name,
        name: this.parcel.name,
        address_2: this.parcel.address_2,
      }
    },
    selectedCarrier() {
      if (!this.selectedCarrierCode || !this.allowedCarriers) {
        return
      }
      return this.allowedCarriers[this.selectedCarrierCode]
    },
    isSelectedCarrierErs() {
      // No data, no ers
      if (!this.shippingProducts || !this.selectedCarrierCode) {
        return
      }

      // Find all shipping products for this carrier.
      const carrierShippingProducts = this.shippingProducts.filter(
        (product) => product.carrier === this.selectedCarrierCode
      )
      if (carrierShippingProducts.length === 0) {
        return false
      }

      // Search shipping products for ers functionality.
      const shippingProductWithErs = carrierShippingProducts.find((product) =>
        product.methods.find((method) => method.functionalities?.ers === true)
      )

      // Ers can only be active for internation returns
      return shippingProductWithErs && this.isInternationalReturn
    },
  },
  mounted() {
    this.v$.$touch()
  },
  methods: {
    changeField(fieldName, value) {
      this.$store.commit(APP_SET_PARCEL, {
        ...this.parcel,
        [fieldName]: value.trim(),
      })
      this.v$[fieldName].$touch()
    },

    async confirm() {
      this.creatingLabel = true
      this.backendErrors = {}

      try {
        await this.$store.dispatch(APP_CREATE_LABEL)
      } catch (err) {
        const responseData = await err.response.json()
        this.backendErrors = responseData.error
        // Unable to create the label, allow the user to try again.
        this.creatingLabel = false
        if (!this.isTelephoneNumberInvalid) {
          this.$router.push({ name: 'error', params: { errorType: ErrorTypes.UNKNOWN } }).catch(handleRouteRejection)
        }
        return
      }

      if (this.returnLocationType === 'store' && this.returnSuccess) {
        this.$router.push({ name: 'confirmation' }).catch(handleRouteRejection)
        return
      }
      this.$router.push({ name: 'label-creation' }).catch(handleRouteRejection)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
.summary__form {
  display: flex;
  flex-direction: column;
}
.summary__contact-details {
  display: flex;
  flex-direction: column;

  @media (min-width: 37.5rem) {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }
}
.contact-details__errors {
  width: 100%;
  display: none;
  @media (min-width: 37.5rem) {
    display: block;
  }
}
.summary__entry {
  width: 100%;

  @media (min-width: 37.5rem) {
    width: 48%;
  }
}
.summary__label {
  margin: 2.25rem 0 0.625rem;
  font-weight: 700;
  display: block;
}
.summary__input {
  border: $default-border;
  border-radius: $default-border-radius;
  padding: 1rem;
  width: 100%;

  &--error {
    border-color: #cc0022;
  }

  &:focus {
    box-shadow: $focus-shadow;
  }
}
.card {
  margin-top: 1.375rem;
}
.card__title {
  font-weight: 700;
  margin: 0 0 0.625rem;
}
.card__group {
  display: flex;
}
.card__icon {
  margin-right: 0.625rem;
  width: 1rem;
}
.card__sub-title {
  margin-bottom: 0.625rem;
}
.card__address-wrap {
  margin-bottom: 1.25rem;
}
.card__button {
  margin-top: 1.25rem;
}
.card--align {
  margin-left: 1.625rem;
}

.card__button-icon {
  margin-right: 0.625rem;
}
</style>
